<template>
  <main id="app">
    <navigation/>
    <transition appear @enter="enter" @leave="leave" mode="out-in">
      <router-view ref="view"/>
    </transition>
    <transition appear name="space">
      <div class="background" ref="background"></div>
    </transition>
  </main>
</template>

<script>
import Background from "@/utils/background";
import { Vector2 } from "three";
import Metas from "@/utils/metas";

import Navigation from "./components/navigation/Navigation";

export default {
  name: "App",
  components: {
    Navigation
  },
  data () {
    return {
      mouse: new Vector2(window.innerWidth*0.5,window.innerHeight*0.5)
    }
  },
  mounted() {
    this.$nextTick(this.initBackground);
  },
  beforeDestroy() {
    window.removeEventListener("mousemove", this.mouseEvent.bind(this), false);
  },
  methods: {
    initBackground() {
      const { background } = this.$refs;
      window.addEventListener("mousemove", this.mouseEvent.bind(this), false);
      this.$store.commit(
        "background",
        new Background({ container: background, u_mouse: this.mouse })
      );
    },
    mouseEvent(event) { 
      this.mouse.x = event.clientX;
      this.mouse.y = event.clientY;
    },
    enter(el, done) { this.$refs.view.introduce(done); },
    leave(el, done) { this.$refs.view.outroduce(done); }
  },
  metaInfo: Metas.app
};
</script>

<style lang="stylus" src="./styles/core.styl"></style>
