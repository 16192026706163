import gsap from "gsap";

export default ({chars}, complete) => {

  gsap.timeline()
  .clear()
  .from(
    chars.title,
    {
      y: "25%",
      opacity: 0,
      textShadow: "0 0 20px rgb(255,255,255)",
      duration: 1,
      ease: "power2.out",
      stagger: { amount: 0.4, from: "left" }
    },
    0
  )
  .from(
    chars.subtitle,
    {
      y: "20%",
      opacity: 0,
      textShadow: "0 0 8px rgb(255,255,255)",
      duration: 0.8,
      ease: "power2.out",
      stagger: { amount: 0.25, from: "left" }
    },
    0.5
  )
  .set(chars.title, { clearProps: "all" })
  .set(chars.subtitle, { clearProps: "all" })
  .call(complete);
}