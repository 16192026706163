<template>
  <transition appear @enter="enter">
    <nav :class="classes">
      <div class="top">
        <router-link class="link logo" ref="logo" to="/">
          P<br>ILI<br>COR<br>P
        </router-link>
      </div>
      <div class="bottom">
        <router-link class="link contact" ref="works" to="/works">
          w<br>ORK<br>S
        </router-link>
        <router-link class="link works" ref="contact" to="/contact">
          CO<br>NTA<br>CT
        </router-link>
      </div>
    </nav>
  </transition>
</template>

<script>
import gsap from "gsap";
import Splitting from "splitting";

export default {
  name: "Navigation",
  data() {
    return {
      isLoaded: false,
    }
  },
  computed: {
    isIntroduced() {
      return this.$store.getters.isIntroduced;
    },
    classes() {
      return ["nav", { loaded: this.isLoaded }];
    }
  },
  methods: {
    enter(el, done) {
      const { logo, contact, works } = this.$refs;

      const logoLetters = Splitting({ target: logo.$el });
      const contactLetters = Splitting({ target: contact.$el });
      const worksLetters = Splitting({ target: works.$el });

      const position = this.isIntroduced ? 0.4 : 4;
      const duration = this.isIntroduced ? 0.75 : 1.5;
      const staggerAmount = this.isIntroduced ? 0.25 : 0.5;

      gsap.timeline()
        .from(
          logoLetters[0].chars,
          {
            y: "-100%",
            opacity: 0,
            textShadow: "0 0 4px rgb(255,255,255)",
            duration,
            ease: "power2.out",
            stagger: { amount: staggerAmount, from: "end" }
          },
          position
        )
        .from(
          contactLetters[0].chars,
          {
            y: "100%",
            opacity: 0,
            textShadow: "0 0 4px rgb(255,255,255)",
            duration,
            ease: "power2.out",
            stagger: { amount: staggerAmount, from: "start" }
          },
          position
        )
        .from(
          worksLetters[0].chars,
          {
            y: "100%",
            opacity: 0,
            textShadow: "0 0 4px rgb(255,255,255)",
            duration,
            ease: "power2.out",
            stagger: { amount: staggerAmount, from: "start" }
          },
          position
        )
        .set(logoLetters[0].chars, { clearProps: "all" })
        .set(contactLetters[0].chars, { clearProps: "all" })
        .set(worksLetters[0].chars, { clearProps: "all" })
        .call(() => {
          this.isLoaded = true;
          done();
        });
    }
  }
};
</script>

<style lang="stylus" scoped>
    @import "~@/styles/core.styl"
    @import "animations.styl"
    @import "navigation.styl"
</style>
