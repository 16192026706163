import Vue from "vue";
import VueAnalytics from "vue-analytics";

import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

Vue.config.productionTip = false;

const isProd = process.env.NODE_ENV === "production";

Vue.use(VueAnalytics, {
  id: "UA-7859186-3",
  router,
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  }
});

(async () => {
  const data = await fetch("/data.json");
  store.commit("works", (await data.json()).works);
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount("#app",);
})();