const base = {
  title: "Pilicorp | Code poetry",
  links: [{
    rel: "canonical",
    href: "https://pilicorp.fr/"
  }]
}
const Metas = {
  app: {
    ...base,
    titleTemplate: "%s | Pilicorp",
    meta: [
        {
        "http-equiv": "Content-Type",
        content: "text/html; charset=utf-8"
        },
        {
        name: "viewport",
        content: "width=device-width, initial-scale=1"
        },
        {
        name: "description",
        content:
            "Unicorn (front-end) dev delivering cutting-edge quality web material — Anthony Busnel."
        },
        {
        property: "og:title",
        content: "%s | Pilicorp"
        },
        {
        property: "og:site_name",
        content: "Pilicorp | Code poetry"
        },
        {
        property: "og:type",
        content: "website"
        },
        {
        property: "og:url",
        content: "https://pilicorp.fr/"
        },
        {
        property: "og:description",
        content:
            "Unicorn (front-end) dev delivering cutting-edge quality web material — Anthony Busnel."
        },
        {
        name: "twitter:card",
        content: "summary"
        },
        {
        name: "twitter:site",
        content: "https://pilicorp.fr/"
        },
        {
        name: "twitter:title",
        content: "%s | Pilicorp"
        },
        {
        name: "twitter:description",
        content:
            "Unicorn (front-end) dev delivering cutting-edge quality web material — Anthony Busnel."
        },
        {
        itemprop: "name",
        content: "%s | Pilicorp"
        },
        {
        itemprop: "description",
        content:
            "Unicorn (front-end) dev delivering cutting-edge quality web material — Anthony Busnel."
        }
    ]
  },
  home: {
    ...base,
    titleTemplate: null
  },
  works: {
    title: "Selected works",
    links: [{
      rel: "canonical",
      href: "https://pilicorp.fr/works"
    }]
  },
  contact: {
    title: "Contact",
    links: [{
      rel: "canonical",
      href: "https://pilicorp.fr/contact"
    }]
  }
};

Object.freeze(Metas);

export default Metas;