import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import Home from "./views/home/Home";

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/contact",
      name: "contact",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/contact/Contact")
    },
    {
      path: "/works",
      name: "works",
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/works/Works")
    }
  ]
});
