import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    background: null,
    introduced: false,
    works: []
  },
  getters: {
    background: state => state.background,
    isIntroduced: state => state.introduced,
    works: state => state.works
  },
  mutations: {
    background(state, background) {
      state.background = background;
    },
    introduced(state, status) {
      state.introduced = status;
    },
    works(state, list) {
      state.works = list;
    }
  }
});
