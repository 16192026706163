import gsap from "gsap";

export default ({title, subtitle, chars}, complete) => {

  gsap.timeline()
  .clear()
  .from(
    chars.title,
    {
      y: "25%",
      opacity: 0,
      textShadow: "0 0 20px rgb(255,255,255)",
      duration: 2,
      ease: "power2.inOut",
      stagger: { amount: 0.7, from: "random" }
    },
    0.8
  )
  .from(title, { y: "15%", duration: 1.5, ease: "power2.inOut" }, 3)
  .from(
    chars.subtitle,
    {
      y: "-20%",
      opacity: 0,
      textShadow: "0 0 8px rgb(255,255,255)",
      duration: 1.5,
      ease: "power2.inOut",
      stagger: { amount: 0.4, from: "random" }
    },
    3
  )
  .from(subtitle, { y: "-40%", duration: 1.5, ease: "power2.inOut" }, 3)
  .set(chars.title, { clearProps: "all" })
  .set(chars.subtitle, { clearProps: "all" })
  .call(complete);
}