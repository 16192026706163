<template>
  <div :class="classes">
    <router-link class="caption" to="works">
        <h1 class="title" ref="title">AN IMAGE IS WORTH A THOUSAND WORDS</h1>
        <p class="subtitle" ref="subtitle">And some websites are worth a thousand worlds</p>
    </router-link>
  </div>
</template>

<script>
import Splitting from "splitting";
import { lerp } from "@/utils/animation";
import Metas from "@/utils/metas";

import initial from "@/views/home/initial";
import introduce from "@/views/home/introduction";
import outroduce from "@/views/home/outroduction";

export default {
  name: "Home",
  data() {
    return {
      isLoaded: false,
      chars: {
        title: [],
        subtitle: []
      },
      time: null
    }
  },
  computed: {
    classes() {
      return ["hero", { loaded: this.isLoaded }];
    },
    isIntroduced() {
      return this.$store.getters.isIntroduced;
    }
  },
  beforeDestroy () {
    cancelAnimationFrame(this.time);
  },  
  methods: {
    introduce(done) {
      this.initChars();

      const { title, subtitle } = this.$refs;
      const chars = this.chars;

      const onComplete = () => {
        this.isLoaded = true;
        this.chars.title = this.setProximity(this.chars.title, 11);
        this.chars.subtitle = this.setProximity(this.chars.subtitle, 5);
        this.move();
        if(!this.isIntroduced) {
          this.$store.commit("introduced", true);
        }
        done();
      }

      this.isIntroduced 
        ? introduce({ chars }, onComplete)
        : initial({ title, subtitle, chars }, onComplete)
      ;

    },
    outroduce(done) {
      const chars = this.chars;
      outroduce({ chars }, done);
    },
    initChars() {
      const { title, subtitle } = this.$refs;
      if(!this.chars.title.length) {
        this.chars.title = Splitting({ target: title })[0].chars;
      }
      if(!this.chars.subtitle.length) {
        this.chars.subtitle = Splitting({ target: subtitle })[0].chars;
      }
    },
    setProximity(chars, amount) {
      return chars.map(char => {
        char.rect = char.getBoundingClientRect();
        char.blur = 0;
        char.amount = amount;
        return char;
      });
    },
    move() {
      const { x, y } = this.$parent.mouse;
      Object.values(this.chars).forEach(chars => {
        chars.forEach(char => {
          const distance = this.distance(char, x, y) - 10;
          const blur = Math.min(char.amount, Math.max(0, (distance*0.05)));
          const roundedBlur = char.amount - ((blur * 10) << 0) * 0.1;

          char.blur = lerp(char.blur, roundedBlur, 0.1);
          char.style = `text-shadow: 0 0 ${char.blur}px rgb(255,255,255)`;
        });
      })
      this.time = requestAnimationFrame(this.move.bind(this));
    },
    distance($el, mouseX, mouseY) {
      return Math.floor(
        Math.sqrt(
          Math.pow(mouseX - ($el.rect.left+($el.rect.width/2)), 2) + 
          Math.pow(mouseY - ($el.rect.top+($el.rect.height/2)), 2)
        )
      );
    }
  },
  metaInfo: Metas.home
};
</script>

<style scoped lang="stylus">
  @import "~@/styles/core"
  @import "Home"
</style>

