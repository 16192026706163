export const starfieldShader = `
#ifdef GL_ES
precision mediump float;
#endif

uniform vec2 u_resolution;
uniform vec2 u_mouse;
uniform float u_time;
uniform vec3 u_light;

#define hashscale .1031
#define sky_color vec3(0.07,0.07,0.11)
#define stars_threshhold 0.998 // threshhold range [0.99, 0.9999]

float hash12(vec2 p)
{
    vec3 p3 = fract(vec3(p.xyx)*hashscale);
         p3 += dot(p3,p3.yzx+19.19);
    return fract((p3.x+p3.y)*p3.z);
}

float noisyField(vec2 pos)
{
    float stars = hash12(pos);
    if ( stars >= stars_threshhold )
        stars = pow((stars-stars_threshhold)/(1.0-stars_threshhold), 1.0);
    else
        stars = 0.0;
    return stars;
}

float starField(vec2 pos)
{
    vec2 floorPos = floor(pos);    
    float v1 = noisyField(floorPos);
    float v2 = noisyField(floorPos + vec2(0.0,1.0));
    float v3 = noisyField(floorPos + vec2(1.0,0.0));
    float v4 = noisyField(floorPos + vec2(1.0,1.0));
    float fractX = fract(pos.x);
    float fractY = fract(pos.y);
    return v1 * (1.0-fractX) * (1.0-fractY) + v2 * (1.0-fractX) * fractY + v3 * fractX * (1.0-fractY) + v4 * fractX * fractY;
}

float circle(vec2 _uv, vec2 _center, float _inner, float _outer) {
	vec2 dist = _uv - _center;
	return 1.0 - smoothstep(_inner-(_inner*0.01), _outer+(_outer*0.01), dot(dist,dist)*5.0);
}

void main()
{
    float fix = u_resolution.x/u_resolution.y;
    vec2 uv = gl_FragCoord.xy/u_resolution.xy - 0.5;
         uv.x *= fix;
    vec2 mouse = vec2(u_mouse.xy/u_resolution.xy - 0.5);
    vec2 position = vec2(mouse.x * fix, -mouse.y);

    vec3 sky = sky_color * gl_FragCoord.y/u_resolution.y;
    
    // Stars
    // vec2 origin = uv * 0.2;
    vec2 origin = uv * 1.0;
    float sampleAngle = atan(origin.y,origin.x);
    vec2 angle = vec2(sin(sampleAngle),cos(sampleAngle));
    vec2 pos = length(origin) * angle * u_resolution - (u_time * 0.67);
    float stars = starField(pos);

    // Spotlight
    float light = circle(uv, position, -0.4, 0.25);
    vec3 spot = vec3(light) * u_light;

    sky += vec3(stars) + spot;
    
    gl_FragColor = mix(vec4(sky, 1.0), vec4(u_light, 1.0), 0.03);

}
`;