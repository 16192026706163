import {
  Camera,
  Color,
  Mesh,
  PlaneBufferGeometry,
  Scene,
  ShaderMaterial,
  Vector2,
  WebGLRenderer
} from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { starfieldShader } from "../shaders/starfield";

import { lerp } from "@/utils/animation";

const DEFAULT_LIGHT = new Color(0.7, 0.3, 0.3);

export default class Background {

  constructor({
    container = console.error("A parent must be defined"),
    u_mouse
  } = {}) {

    this.initialize(u_mouse);

    const app = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    const renderer = this.setRenderer(app);
    const scene = this.setScene();
    const camera = this.setCamera();

    const uniforms = {
      u_resolution: {
        type: "v2",
        value: new Vector2(
          renderer.domElement.width,
          renderer.domElement.height
        )
      },
      u_time: { type: "f", value: 1.0 },
      u_mouse: { type: "v2", value: this.u_mouse },
      u_light: { type: "v3", value: this.u_light }
    };

    const mesh = this.setMesh(uniforms, starfieldShader);

    scene.add(mesh);

    container.appendChild(renderer.domElement);

    const composer = new EffectComposer(renderer);
    const renderPass = new RenderPass(scene, camera);

    composer.addPass(renderPass);

    const effect = {
      uniforms: {
        "tDiffuse": { value: null },
        "u_resolution": {
          type: "v2",
          value: new Vector2(
            renderer.domElement.width,
            renderer.domElement.height
          )
        },
        "u_mouse": { type: "v2", value: this.u_mouse },
        "u_velocity": { value: 0.0 }
      },
      vertexShader: 
`
varying vec2 vUv;
void main() {
  vUv = uv;
  gl_Position = projectionMatrix*modelViewMatrix*vec4(position,1.0);
}
`,
      fragmentShader: 
`
uniform sampler2D tDiffuse;
varying vec2 vUv;
uniform vec2 u_resolution;
uniform vec2 u_mouse;
uniform float u_velocity;

float circle(vec2 _uv, vec2 _center, float _inner, float _outer) {
	vec2 dist = _uv - _center;
	return 1.0 - smoothstep(_inner-(_inner*0.01), _outer+(_outer*0.01), dot(dist,dist)*5.0);
}

void main()
{
    float fix = u_resolution.x/u_resolution.y;
    vec2 uv = gl_FragCoord.xy/u_resolution.xy - 0.5;
        uv.x *= fix;
    vec2 mouse = vec2(u_mouse.xy/u_resolution.xy - 0.5);
    vec2 position = vec2(mouse.x * fix, -mouse.y);

    // Lens
    float lens = circle(uv, position, 0.0, 0.1 + u_velocity*10.0) * u_velocity;

    vec2 warped = mix(vUv, u_mouse, lens*0.0005);
    vec4 texel = texture2D(tDiffuse, warped);

    // vec2 nUv = vUv;
    // float c = circle(uv, position, 0.0, 0.2);
    // float r = texture2D(tDiffuse, nUv.xy += c * (u_velocity * .5)).x;
    // float g = texture2D(tDiffuse, nUv.xy += c * (u_velocity * .525)).y;
    // float b = texture2D(tDiffuse, nUv.xy += c * (u_velocity * .55)).z;
    // vec4 texel = vec4(r, g, b, 1.);

    gl_FragColor = texel;
}
`
    }

    const customPass = new ShaderPass(effect);
    composer.addPass(customPass);

    const render = () => {

      this.speed = Math.sqrt(
        (this.preMouse.x- this.u_mouse.x)**2 +
        (this.preMouse.y- this.u_mouse.y)**2
      );
  
      this.postSpeed = lerp(this.postSpeed, this.speed, 0.15);

      this.mouse.x = lerp(this.mouse.x, this.u_mouse.x, 0.15);
      this.mouse.y = lerp(this.mouse.y, this.u_mouse.y, 0.15);
  
      this.preMouse.x = this.u_mouse.x;
      this.preMouse.y = this.u_mouse.y;

      this.u_light.r = lerp(this.u_light.r, this.light.r, 0.02);
      this.u_light.g = lerp(this.u_light.g, this.light.g, 0.02);
      this.u_light.b = lerp(this.u_light.b, this.light.b, 0.02);
  
      uniforms.u_time.value += 0.01;
      uniforms.u_mouse.value = this.mouse;
      uniforms.u_light.value = this.u_light;

      customPass.uniforms.u_mouse.value = this.mouse;
      customPass.uniforms.u_velocity.value = Math.min(this.postSpeed*0.001, 0.01);

      composer.render();
    };

    const animate = () => {
      requestAnimationFrame( animate );
      render();
    };

    animate();

  }

  initialize(u_mouse) {
    this.speed = 0;
    this.postSpeed = 0;
    this.light = DEFAULT_LIGHT;
    this.mouse = new Vector2(0.0,0.0);
    this.preMouse = new Vector2(0.0,0.0);

    this.u_light = this.light;
    this.u_mouse = u_mouse;
  }

  setRenderer(app) {
    const renderer = new WebGLRenderer();
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(app.width, app.height);
    return renderer;
  }

  setScene() {
    return new Scene();
  }
  
  setCamera() {
    const camera = new Camera();
    camera.position.z = 1;
    return camera;
  }

  setMesh(uniforms, fragmentShader) {
    const geometry = new PlaneBufferGeometry(2,2);
    const material = new ShaderMaterial({ uniforms, fragmentShader });
    return new Mesh(geometry, material);
  }

  setLight(light) {
    this.light = light;
  }

  resetLight() {
    this.setLight(new Color(0.7, 0.3, 0.3));
  }

}
