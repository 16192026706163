import gsap from "gsap";

export default ({chars}, complete) => {

  gsap.timeline()
    .clear()
    .to(
      chars.title,
      {
        y: "-25%",
        opacity: 0,
        textShadow: "0 0 20px rgb(255,255,255)",
        duration: 0.4,
        ease: "power2.in",
        stagger: { amount: 0.2, from: "left" }
      },
      0
    )
    .to(
      chars.subtitle,
      {
        y: "-20%",
        opacity: 0,
        textShadow: "0 0 8px rgb(255,255,255)",
        duration: 0.3,
        ease: "power2.in",
        stagger: { amount: 0.1, from: "left" }
      },
      0.2
    )
    .call(complete);
}